import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Tags from '../components/Tags';
import Block from '../components/Block';
import { Menu } from '../components/Menu';
import kebabCase from "lodash/kebabCase"
import Seo from '../components/Seo';
import wavePattern from '../images/wave-pattern.svg';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from 'styled-components';
import { ImgAltWrapper } from '../templates/blog-post';

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || 'Title';
	const posts = data.allMarkdownRemark.nodes;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo
				title={siteTitle}
				description="Born in Siberia. Been to 50 countries. Stories on culture, language, education and design."
			/>
			<Menu/>
			<Block size="small">
				<WritingContainer>
					<ol style={{ listStyle: 'none' }}>
						{posts.map((post) => {
							const title = post.frontmatter.title || post.fields.slug;
							const cover = post.frontmatter.showCoverImage ? getImage(post.frontmatter.meta.image) : '';

							return (
								<li key={post.fields.slug}>
									<article
										className="post-list-item"
										itemScope
										itemType="http://schema.org/Article"
									>
										<header>
											<Tags>
												<ul>
													{post.frontmatter.tags.map(tag => (
														<li key={tag}>
															<Link to={`/tags/${kebabCase(tag)}/`}>
															#{tag}
															</Link>
														</li>
													))}
												</ul>
											</Tags>
											<h1>
												<Link to={post.fields.slug} itemProp="url">
													<span itemProp="headline">{title}</span>
												</Link>
											</h1>	
											<p className="author label">{post.frontmatter.date}</p>									
										</header>
										{cover && post.frontmatter.showCoverImage && (
											<CoverImageWrapper>
												<GatsbyImage image={cover} alt={post.frontmatter.meta.imgAlt} />
												{post.frontmatter.meta.imgAlt && (<ImgAltWrapper>{post.frontmatter.meta.imgAlt}</ImgAltWrapper>)}
											</CoverImageWrapper>
										)}
										<section
											dangerouslySetInnerHTML={{ __html: post.html }}
											itemProp="articleBody"
											className="articleBody"
										/>
									</article>
									<div className="wave"></div>
								</li>
							);
						})}
					</ol>
				</WritingContainer>
			</Block>
		</Layout>
	);
};

const CoverImageWrapper = styled.div`
	margin-top: var(--spacing-6);
	margin-bottom: var(--spacing-8);
`;

const WritingContainer = styled.div`
	.post-list-item {
	margin: var(--spacing-8) auto var(--spacing-8);
		header {
			margin-bottom: var(--spacing-4);
		}
	}

	.wave {
	height: 32px;
	width: 100%;
	max-width: 1000px;
	margin: 80px auto 64px;
	background:url(${wavePattern});
	background-repeat: repeat-x;
	}

	li:last-child .wave {
		display: none;
	}
`;

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {
		fields: [frontmatter___date], order: DESC }
		filter: { frontmatter: { language: { eq: "english" } } }
	) {
      nodes {
        excerpt
		html
        fields {
          slug
        }
        frontmatter {
			title
			date(formatString: "D MMM, YYYY")
			description
			showCoverImage
			showUpdated
			tags
			author
			meta {
				image {
					childImageSharp {
					gatsbyImageData(width: 1300, quality: 90)
					original {
						src
						width
						height
						}
					}
				}
				imgAlt
			}
        }
      }
    }
  }
`;
